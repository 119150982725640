export default [
    {
        "id": "magical-loading",
        "title": "magical loading",
        "intro": "Dududu~ The page is loading...",
        "thumb": "/images/thumb/magical-loading.png",
        "link": "/content/magical-loading",
    }, {
        "id": "flip",
        "title": "flip",
        "intro": "Flip flip flip~",
        "thumb": "/images/thumb/flip.png",
        "link": "/content/flip",
    }, {
        "id": "hello",
        "title": "hello",
        "intro": "Say hello to the world!",
        "thumb": "/images/thumb/hello.png",
        "link": "/content/hello",
    } 
    // {
    //     "id": "fire",
    //     "title": "fire",
    //     "intro": "A thing which can make you warm.",
    //     "thumb": "/images/thumb/hello.png",
    //     "link": "/content/fire",
    // }
]